import request from '@/utils/request'

// 项目概览
const api = {
  projectDetail: '/boss/pad/pro/info/detail',
  projectList: '/boss/pad/pro/list',
  proDetail: '/boss/pad/pro/detail',
  icfList: '/boss/pad/pro/pts/icfList'
}

// 项目详情
export function getProjectDetail (parameter) {
  return request({
      url: api.projectDetail,
      method: 'post',
      data: parameter
  })
}

// 项目列表
export function getProjectList (parameter) {
  return request({
      url: api.projectList,
      method: 'post',
      data: parameter
  })
}

export function getProDetail (parameter) {
  return request({
      url: api.proDetail,
      method: 'post',
      data: parameter
  })
}

export function getIcfList (parameter) {
  return request({
      url: api.icfList,
      method: 'post',
      data: parameter
  })
}

// 下载
export function exportPDF (parameter) {
  return request({
    url: '/common/downloadFile',
    method: 'get',
    params: parameter,
    responseType: 'blob'
  })
}

export function Prompt (parameter) {
  return request({
    url: '/boss//passport/modifyPdw/Prompt',
    method: 'get',
    params: parameter
  })
}
