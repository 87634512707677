import request from '@/utils/request'

// 参与者
const api = {
  addPts: '/boss/pad/pro/addPts',
  PtsDetails: '/boss/pad/pro/manage/getPtsDetails', // 参与者详细信息
  doctorList: '/boss/pad/pro/manage/listDoctor', // 显示当前项目研究人员
  upDatePtsStatus: '/boss/pad/pro/manage/updatePtsStatus', // 修改当前状态
  updatePtsReId: '/boss/pad/pro/manage/updatePtsReId', // 研究人员修改或添加
  updatePtsAuth: '/boss/pad/pro/manage/updatePtsAuth', // 参与者审批
  list: '/boss/pad/pro/manage/showIcfSign',
  icfApply: '/boss/pad/pro/icf/apply',
  startSign: '/boss/pad/pro/start/sign',
  answerIcf: '/boss/pad/pro/answerIcf',
  addLog: '/boss/pad/pro/icf/addLog',
  listQuestion: '/boss/pad/pro/icf/listQuestion',
  reinviteSubject: '/boss/pad/pro/anewInvitePts' // 重新邀请参与者
}

export function list (parameter) {
  return request({
      url: api.list,
      method: 'post',
      data: parameter
  })
}

export function addPts (parameter) {
  return request({
      url: api.addPts,
      method: 'post',
      data: parameter
  })
}

export function PtsDetails (parameter) {
  return request({
      url: api.PtsDetails,
      method: 'post',
      data: parameter
  })
}

export function doctorList (parameter) {
  return request({
      url: api.doctorList,
      method: 'post',
      data: parameter
  })
}

export function upDatePtsStatus (parameter) {
  return request({
      url: api.upDatePtsStatus,
      method: 'post',
      data: parameter
  })
}

export function updatePtsReId (parameter) {
  return request({
      url: api.updatePtsReId,
      method: 'post',
      data: parameter
  })
}

export function updatePtsAuth (parameter) {
  return request({
      url: api.updatePtsAuth,
      method: 'post',
      data: parameter
  })
}

export function icfApply (parameter) {
  return request({
      url: api.icfApply,
      method: 'post',
      data: parameter
  })
}

export function startSign (parameter) {
  return request({
      url: api.startSign,
      method: 'post',
      data: parameter
  })
}

export function answerIcf (parameter) {
  return request({
      url: api.answerIcf,
      method: 'post',
      data: parameter
  })
}

export function addLog (parameter) {
  return request({
      url: api.addLog,
      method: 'post',
      data: parameter
  })
}

export function getListQuestion (parameter) {
  return request({
      url: api.listQuestion,
      method: 'get',
      params: parameter
  })
}

// 重新邀请参与者
export function reinviteSubject (parameter) {
  return request({
      url: api.reinviteSubject,
      method: 'post',
      data: parameter
  })
}
