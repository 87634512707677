<template>
  <a-modal
    title="您对参加本研究的同意"
    :visible="visible"
    :footer="null"
    @cancel="handleCancel"
  >
    <div class="form-wrap">
      <a-form-model :model="form" labelAlign="left" ref="ruleForm">
        <a-form-model-item v-for="item in list" :key="item.id" :label="item.title" :prop="item.titleCode">
          <div v-if="item.type === 1">
            <a-radio-group v-model="form[item.titleCode]">
              <a-radio :style="radioStyle" v-for="(min, i) in item.options" :key="i" :value="i" @focus="focus($event, item.options, item.titleCode)">
                {{ min.name }}
              </a-radio>
            </a-radio-group>
            <a-input placeholder="请输入" v-if="other[item.titleCode] !== undefined" type="text" v-model="other[item.titleCode]" />
          </div>
          <div v-if="item.type === 2">
            <a-checkbox-group :value="form[item.titleCode]" @change="onChange($event, item.titleCode)">
              <a-row>
                <a-col :span="24" v-for="(min, i) in item.options" :key="i">
                  <a-checkbox v-model="min.check" :value="i" @change="checkboxChange($event, item.options, item.titleCode)">
                    {{ min.name }}
                  </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
            <a-input placeholder="请输入" v-if="other[item.titleCode] !== undefined" v-model="other[item.titleCode]" />
          </div>
          <div v-if="item.type === 3">
            <a-textarea placeholder="请输入" v-model="form[item.titleCode]" :auto-size="{ minRows: 3, maxRows: 5 }" @input="textChange($event, item.titleCode)" />
          </div>
        </a-form-model-item>
      </a-form-model>
      <div class="agreen">{{ allCheck ? '我已充分知情并且同意签署' : '还需要确认上述相关问题' }}</div>
      <a-button
        class="agreen-btn"
        type="primary"
        @click="handleOk"
        :disabled="!allCheck"
      >
        确定签署知情同意书
      </a-button>
    </div>
  </a-modal>
</template>
<script>
import { startSign } from '@/api/overview/subject'

export default {
  props: [ 'applyId', 'researcherId', 'question' ],
  data () {
    return {
      visible: false,
      form: {},
      other: {},
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px'
      },
      allCheck: false
    }
  },
  computed: {
    list () {
      return this.question.map(item => {
        this.$set(this.other, item.titleCode, undefined)
        return {
          ...item,
          options: item.options.split('$').map(node => {
            return {
              name: node,
              check: false
            }
          })
        }
      })
    }
  },
  methods: {
    open () {
      this.visible = true
    },
    handleCancel () {
      this.visible = false
      this.$refs.ruleForm.resetFields()
      this.form = {}
      this.other = {}
    },
    handleOk () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const questionnaireResult = Object.keys(this.form).map(node => {
            return {
              titleCode: node,
              selected: Array.isArray(this.form[node]) ? this.form[node].join('$') : this.form[node],
              otherText: this.other[node]
            }
          })
          await startSign({
            applyId: this.applyId,
            researcherNo: this.researcherId,
            questionnaireResult: JSON.stringify(questionnaireResult)
          })
          this.$router.replace({
            name: 'ConsentDetail',
            query: { id: this.applyId }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    focus (e, arr, id) {
      if (arr[e.target._value].name === '其他') {
        this.other[id] = ''
      } else {
        this.other[id] = undefined
      }
      setTimeout(() => {
        this.isSubmit()
      }, 100)
    },
    onChange (e, id) {
      if (e.length > 0) {
        this.form[id] = e
      } else {
        delete this.form[id]
      }
      setTimeout(() => {
        this.isSubmit()
      }, 100)
    },
    checkboxChange (e, arr, id) {
      if (arr[e.target.value].name === '其他' && e.target.checked) {
        this.other[id] = ''
      } else if (arr[e.target.value].name === '其他' && !e.target.checked) {
        this.other[id] = undefined
      }
      this.$forceUpdate()
    },
    textChange (e, id) {
      if (e.target.value) {
        this.isSubmit()
      } else {
        delete this.form[id]
        this.allCheck = false
      }
    },
    isSubmit () {
      if (this.list.length === Object.keys(this.form).length) {
        this.allCheck = true
      } else {
        this.allCheck = false
      }
    }
  }
}
</script>
<style scoped lang="less">
  .form-wrap {
    width: 100%;
    max-height: 700px;
    overflow: auto;
  }
  .agreen {
    text-align: center;
    padding: 10px 0 0;
  }
  .agreen-btn {
    width: 80%;
    margin: 20px 0 20px 10%;
  }
  /deep/.ant-form-item-label-left {
    line-height: 26px;
    padding-bottom: 8px;
  }
  /deep/.ant-form-item label {
    white-space: break-spaces;
  }
  /deep/.ant-form-item-label > label::after {
    content: ''
  }
</style>
