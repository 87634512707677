<template>
  <div>
    <div class="menu-header">
      <div class="menu-title">
        <a-icon class="arrow" type="arrow-left" @click="$router.go(-1)" />
        <div class="title">参与者列表</div>
      </div>
      <a-button type="primary" @click="fileDownload" >文件下载</a-button>
    </div>
    <a-spin :spinning="spinning" style="text-align:center;margin-top:10px">
      <div >
        <!-- <pdf
          ref="pdf"
          v-for="i in numPages"
          :key="i"
          :src="fileUrl"
          :page="i"
        ></pdf> -->
        <div id="pdf" :style="{'height':modalHeight + 'px'}">
          <div id="demo"></div>
        </div>
        <div v-if="btnVisible" class="bottom-view">
          <a-button icon="edit" type="primary" class="color" @click="onSign(1)">纸质签署</a-button>
          <a-button icon="edit" type="primary" @click="onSign(2)" >电子签署</a-button>
        </div>
      </div>
    </a-spin>
    <a-modal
      width="700px"
      title="电子签署"
      :visible="qrVisible"
      :footer="null"
      @cancel="qrVisible = false"
    >
      <div style="display:flex;justify-content:center">
        <div style="margin-right:20px;">
          <vue-qr
            ref="qrCodeUrl"
            :text="yjUrl"
            :size="200">
          </vue-qr>
          <div class="code-text">研究者</div>
        </div>
        <div>
          <vue-qr
            ref="qrCodeUrl"
            :text="ssUrl"
            :size="200">
          </vue-qr>
          <div class="code-text">参与者</div>
        </div>
        <div v-if="witnessUrl">
          <vue-qr
            ref="qrCodeUrl"
            :text="witnessUrl"
            :size="200">
          </vue-qr>
          <div class="code-text">见证者</div>
        </div>
      </div>
    </a-modal>
    <a-modal
      title="纸质签署"
      :visible="paVisible"
      @ok="handleSubmit"
      @cancel="paCancel"
    >
      <div>
        <van-uploader
          accept=".pdf, .png, .jpg, .jpeg"
          v-model="fileList"
          multiple
          :after-read="afterRead"
          @click-preview="handlePer"/>
      </div>
    </a-modal>
    <a-modal
      width="90%"
      title="文件预览"
      :visible="viewVisible"
      :footer="null"
      @cancel="viewCancel"
    >
      <div id="pdfview" style="height:70vh;"></div>
    </a-modal>
    <PopQuestion ref="questionRef" :applyId="$route.query.id" :researcherId="$route.query.researcherId" :question="question"></PopQuestion>
  </div>

</template>
<script>
// import axios from 'axios'
import vueQr from 'vue-qr'
// import pdf from 'vue-pdf-sign'
import { getIcfList, exportPDF } from '@/api/overview/overview'
import { getSignUrl, upload, applyPaper, getDetail } from '@/api/consent/consent'
import { startSign } from '@/api/overview/subject'
import { exportPdf } from '@/utils/util'
import PopQuestion from './components/popQueation.vue'
import Pdfh5 from 'pdfh5'
import 'pdfh5/css/pdfh5.css'
export default {
  components: { vueQr, PopQuestion },
  data () {
    return {
      id: '',
      proId: '',
      researchId: '',
      icfId: '',
      btnVisible: false,
      qrVisible: false,
      paVisible: false,
      viewVisible: false,
      yjUrl: '',
      ssUrl: '',
      flowId: '',
      witnessUrl: '',
      witnessPhone: '',
      ptsAccountId: '',
      piAccountId: '',
      fileUrl: null,
      pdfUrl: null,
      numPages: null,
      spinning: false,
      timer: null,
      pdfh5: null,
      pdfview: null,
      fileList: [],
      question: []
    }
  },
  mounted () {
    this.proId = this.$route.query.proId
    this.researchId = this.$route.query.researchId
    this.id = this.$route.query.id
    this.icfId = this.$route.query.icfId

    this.getDetail()

    if (this.timer == null) {
      this.getProDetail()
      this.timer = setInterval(() => {
        this.getProDetail()
      }, 5 * 1000)
    }
  },
  computed: {
    modalHeight () {
      return Number(document.documentElement.clientHeight) - 280
    }
  },
  destroyed () {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
  },
  methods: {
    async fileDownload () {
      const res = await exportPDF({ fileName: this.pdfUrl })
      this.$message.success('下载成功')
      exportPdf('知情文件', res)
    },
    getDetail () {
      console.log(this.icfId)
      getDetail({ id: this.icfId, applyId: this.id }).then(res => {
         const dataList = JSON.parse(JSON.stringify(res))
         if (dataList && dataList.detailList && dataList.detailList.length) {
            dataList.detailList.forEach(item => {
            this.$set(item, 'isExpand', false)
           })
         }
        if (res.propaganda === null) {
          dataList.propaganda = ''
        }
        this.info = dataList
        this.question = res.proIcfQuestionnaireList
      })
    },
    getProDetail () {
      getIcfList({
        proId: this.proId,
        researchId: this.researchId,
        icfId: this.icfId

      }).then(res => {
        if (res.list && res.list.length) {
          res.list.forEach(item => {
            if (item.id === parseInt(this.id)) {
              this.flowId = item.flowId
              this.piAccountId = item.piPhone
              this.ptsAccountId = item.ptsPhone
              this.witnessPhone = item.witnessPhone

              if (item.finshFileUrl) {
                // 如果签署完成 用签署完成的文件 并 隐藏 按钮
                this.getNumPages(this.$options.filters.dealImageUrl(item.finshFileUrl))
                this.pdfUrl = item.finshFileUrl
                this.btnVisible = false
                this.qrVisible = false
                this.paVisible = false
              } else {
                // 用待签署文件
                this.getNumPages(this.$options.filters.dealImageUrl(item.signFileUrl))
                this.pdfUrl = item.signFileUrl
                this.btnVisible = true
              }
            }
          })
        }
      })
    },
    async getSignUrl () {
      this.yjUrl = await getSignUrl({ flowId: this.flowId, phone: this.piAccountId })
      this.ssUrl = await getSignUrl({ flowId: this.flowId, phone: this.ptsAccountId })
      if (this.witnessPhone) {
        this.witnessUrl = await getSignUrl({ flowId: this.flowId, phone: this.witnessPhone })
      }
      this.qrVisible = true
    },
    getNumPages (url) {
      if (this.fileUrl === null) {
        // console.log('初始文件', url)
        // this.fileUrl = url
        // this.spinning = true
        // const loadingTask = pdf.createLoadingTask(this.fileUrl)
        // this.spinning = true
        // this.numPages = null
        // loadingTask.promise.then(pdf => {
        //   this.numPages = pdf.numPages
        //   this.initPage()
        // }).catch(err => {
        //   console.error('pdf 加载失败', err)
        //   this.initPage()
        // })
        this.fileUrl = url
        this.$nextTick(() => {
          this.newPdfUrl = 'demo.pdf'
          this.pdfh5 = new Pdfh5('#demo', {
            pdfurl: url,
            lazy: true
          })
        }, 200)
      } else if (this.fileUrl !== url) {
        this.fileUrl = url
        console.log('更新文件', url)
      }
    },
    getview (url) {
      this.$nextTick(() => {
        this.newPdfUrl = 'demo.pdf'
        this.pdfview = new Pdfh5('#pdfview', {
          pdfurl: url,
          lazy: true,
          cMapUrl: 'https://unpkg.com/pdfjs-dist@2.0.943/cmaps/' // 插件默认的地址证书过期了
        })
      }, 1200)
    },
    initPage () {
      this.spinning = false
      this.$nextTick(() => {
         window.scrollTo(0, 0)
      }, 200)
    },
    // 文件上传
    async afterRead (file) {
      file.status = 'uploading'
      file.message = '上传中...'
      const formData = new FormData()
      formData.append('file', file.file)
      const res = await upload(formData)
      file.status = 'success'
      file.fileUrl = res
    },
    // 纸质签署pdf文件预览
    handlePer (e) {
      if (e.file.type === 'application/pdf') {
        this.viewVisible = true
        this.getview(URL.createObjectURL(e.file))
      }
    },
    viewCancel () {
      this.viewVisible = false
    },
    // 纸质签署提交
    async handleSubmit () {
      if (this.fileList.length) {
        const params = {
          proId: this.proId,
          applyId: this.$route.query.id,
          fileUrls: this.fileList.map(item => item.fileUrl).join(',')
        }
        await applyPaper(params)
        this.paVisible = false
        this.$message.success('签署成功')
        this.$router.replace({
          name: 'ConsentDetail',
          query: { id: this.$route.query.id }
        })
      }
    },
    // 纸质签署取消提交关闭弹窗
    paCancel () {
      this.fileList = []
      this.paVisible = false
    },
    async onSign (e) {
      if (e === 1) { // 纸质签署
        const params = {
          proId: this.proId,
          applyId: this.$route.query.id
        }
        await applyPaper(params)
        this.$router.replace({
          name: 'ConsentDetail',
          query: { id: this.$route.query.id }
        })
      } else { // 电子签署、问卷填写
        if (this.question.length > 0) {
          this.$refs.questionRef.open()
        } else {
          await startSign({
            applyId: this.$route.query.id,
            researcherNo: this.$route.query.researcherId
          })
          this.$router.replace({
            name: 'ConsentDetail',
            query: { id: this.$route.query.id }
          })
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
  .menu-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .menu-title {
      display: flex;
      align-items: center;
    }
  }
  .pdf-content{
    display: flex;
    flex-direction: column;
  }
  .bottom-view{
    padding: 20px 20%;
    display: flex;
    justify-content: space-between;
    .color{
      background-color: #FF8F9B;
      border-color: #FF8F9B;
    }
  }
  .code-text {
    text-align: center;
  }
</style>
